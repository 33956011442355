import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";

export const useTeamStore = defineStore("team", {
  state: () => ({
    team: {},
    teams: [],
    activeTeam: {},
  }),
  actions: {
    async getTeam() {
      const { token } = useAuth();
      const fetch = await $fetch(`${useRuntimeConfig().public.api}/team/get`, {
        query: {
          team: payload.team,
        },
        headers: {
          authorization: token.value,
        },
      });
      if (fetch) {
        this.team = fetch;
      }
    },
    async getTeams() {
      const { token, data } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/user/teams`,
        {
          query: {
            username: data.value.username,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        // console.log("FETCH DATA", fetch.data.value);
        this.teams = fetch.teams;
        // console.log("FETCH", fetch.teams);
      }
    },
    async joinTeam(payload) {
      const { token } = useAuth();
      const post = await $fetch(`${useRuntimeConfig().public.api}/team/join`, {
        method: "POST",
        body: {
          invite: payload.invite,
        },
        headers: {
          authorization: token.value,
        },
      });
      if (post) {
        //
      }
    },
    async makeOwner(payload) {
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/member/owner`,
        {
          method: "POST",
          body: {
            member: payload.member,
            team: payload.team,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        //
      }
    },
    async changeTeam(payload) {
      const { token, data } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/user/teams/active`,
        {
          method: "POST",
          body: {
            tenant: payload._id,
            username: data.value.username,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        // console.log("POST", post);
        // this.activeTeam = payload;
      }
    },
    async getActiveTeam(payload) {
      const { token, data } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/user/team/active`,
        {
          method: "POST",
          body: {
            username: data.value.username,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        this.activeTeam = post;
        // console.log(post.data.value);
      }
    },
    resetTeam() {
      this.activeTeam = {};
      this.teams = [];
    },
    async addTeam(payload) {
      const { token, data } = useAuth();
      const post = await $fetch(`${useRuntimeConfig().public.api}/user/teams`, {
        method: "POST",
        body: {
          name: payload.name,
          username: data.value.username,
        },
        headers: {
          authorization: token.value,
        },
      });
      if (post) {
        // console.log("PDATA", post);
        // this.teams.push(post.data);
      }
    },
    async deleteTeam(payload) {
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/delete`,
        {
          method: "POST",
          body: {
            id: payload.id,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        //
      }
    },
    // async setDomain(payload) {
    //   const { token } = useAuth();
    //   const post = await useFetch(
    //     `${useRuntimeConfig().public.api}/user/team/domain`,
    //     {
    //       method: "POST",
    //       body: {
    //         id: payload.id,
    //       },
    //       headers: {
    //         authorization: token.value,
    //       },
    //     }
    //   );
    //   if (post.data) {
    //     this.teams.push(post.data.value);
    //   }
    // },
    async removeMember(payload) {
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/member/remove`,
        {
          method: "POST",
          body: {
            team: this.activeTeam._id,
            member: payload.member,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        this.activeTeam.members.splice(payload.index, 1);
      }
    },
    async getTeamInvites() {
      const { token } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/team/invites`,
        {
          query: {
            team: this.activeTeam._id,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.activeTeam.invites = fetch;
      }
    },
    async resendInvite(payload) {
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/invite/resend`,
        {
          method: "POST",
          body: {
            id: payload.id,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        //
      }
    },
    async removeInvite(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/invite/remove`,
        {
          method: "POST",
          body: {
            id: payload.id,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        teamStore.getTeamInvites();
      }
    },
    async inviteToTeam(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/invite`,
        {
          method: "POST",
          body: {
            email: payload.email,
            team: this.activeTeam._id,
            name: this.activeTeam.name,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        teamStore.getTeamInvites();
      }
    },
    async disconnectStripe(payload) {
      const { token } = useAuth();
      const post = await $fetch(
        `${useRuntimeConfig().public.api}/team/stripe/disconnect`,
        {
          method: "POST",
          body: {
            team: this.activeTeam._id,
            token: this.activeTeam.services.stripe.token.access,
            account: this.activeTeam.services.stripe.properties.stripe_user_id,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (post) {
        this.activeTeam.services.stripe = {};
      }
    },
  },
  // persist: true,
});
